import { $generateHtmlFromNodes } from '@lexical/html';
import { ListItemNode, ListNode } from '@lexical/list';
import { CheckListPlugin } from '@lexical/react/LexicalCheckListPlugin';
import { InitialConfigType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { skipToken } from '@reduxjs/toolkit/query';
import { AudioPlayer } from 'features/ui/audio/audioPlayer';
import { Divider } from 'features/ui/divider/divider';
import { FormError } from 'features/ui/form/formError';
import { ImageEdit } from 'features/ui/image-edit/imageEdit';
import { JustifyCenter } from 'features/ui/layout/justifyCenter';
import { RouteLayout } from 'features/ui/layout/routeLayout';
import { TagBadge } from 'features/ui/tags/tagBadge';
import { Typography } from 'features/ui/typography/typography';
import { EditorState, LexicalEditor } from 'lexical';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import { router } from 'routing/routes';
import paths from 'routing/utils';
import { useFetchBlob } from 'shared/hooks/useFetchBlob';
import { useInterval } from 'shared/hooks/useInterval';
import { usePrevious } from 'shared/hooks/usePrevious';
import { PredefinedNarrator } from 'shared/types/narrator';
import { PredefinedTag } from 'shared/types/predefinedTag';
import { Creator } from 'shared/types/story';
import { Tag } from 'shared/types/tag';
import { Variant } from 'shared/types/template';
import { SubscriptionType } from 'shared/types/users';
import { validationMessage } from 'shared/utils/formUtils';
import { useGetPredefinedTagsQuery } from 'store/api/endpoints/predefinedTagsEndpoint';
import { useGetStoryByIdQuery } from 'store/api/endpoints/storyEndpoints';
import {
  useCreateTemplateMutation,
  useGenerateStoryAudioMutation,
  useGetTemplateByIdQuery,
  useIsGeneratingAudioQuery,
  useUpdateTemplateMutation,
  useUploadStoryImageMutation
} from 'store/api/endpoints/templateEndpoint';
import { useAppDispatch } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import { getMainTemplateParams } from './admin.utils';
import { AdminNarratorPicker } from './adminNarratorPicker';
import ContentEditable from './lexical/contentEditable';
import './lexical/lexical.scss';
import { OnChangePlugin } from './lexical/onChangePlugin';
import ToolbarPlugin from './lexical/toolbarPlugin';

type StoryEditorFormValues = {
  hidden: boolean;
  title: string;
  original: string;
  description: string;
  pegi: number;
  charactersNumber: string;
  variants: Variant[];
  activitiesAndGames?: { activitiesAndGames: string; activitiesAndGamesLexical: string };
  talkSubjects?: { talkSubjects: string; talkSubjectsLexical: string };
};

const defaultPredefinedTags: PredefinedTag[] = [];

export const AdminStoryEditor = () => {
  const [selectedNarrator, setSelectedNarrator] = useState<PredefinedNarrator | null>(null);
  const [avatar, setAvatar] = useState<File | null>(null);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  // redux
  const dispatch = useAppDispatch();
  // rtk
  const [searchParams] = useSearchParams();
  const storyId = searchParams.get('storyId');
  const templateId = searchParams.get('templateId');
  const [createTemplate] = useCreateTemplateMutation();
  const [updateTemplate] = useUpdateTemplateMutation();
  const [uploadImage] = useUploadStoryImageMutation();
  const [generateAudio] = useGenerateStoryAudioMutation();
  const { data: isGenerating, refetch: refetchIsGenerating } = useIsGeneratingAudioQuery();
  const { data: story, refetch: refetchStory } = useGetStoryByIdQuery(storyId ?? skipToken);
  const { data: template, refetch: refetchTemplate } = useGetTemplateByIdQuery(templateId ?? skipToken);
  const { data: predefinedTags = defaultPredefinedTags } = useGetPredefinedTagsQuery();
  // other
  const { blob: imageBlob } = useFetchBlob(story?.image, 'common');
  const { blob: audioBlob } = useFetchBlob(story?.audio?.fileName, 'common');

  const onSubmit = (data: StoryEditorFormValues) => {
    const templateParams = getMainTemplateParams(data.charactersNumber);

    if (story && template) {
      updateTemplate({
        templateDTO: {
          id: template.id,
          templateParams,
          variants: data.variants
        },
        storyDTO: {
          ...story,
          content: data.original,
          title: data.title,
          hidden: data.hidden,
          description: data.description,
          pegi: data.pegi,
          activitiesAndGames: data.activitiesAndGames?.activitiesAndGames,
          activitiesAndGamesLexical: data.activitiesAndGames?.activitiesAndGamesLexical,
          talkSubjects: data.talkSubjects?.talkSubjects,
          talkSubjectsLexical: data.talkSubjects?.talkSubjectsLexical,
          tags: selectedTags.map(st => ({ predefined: true, value: st.value }))
        }
      })
        .unwrap()
        .then(async () => {
          if (avatar) {
            try {
              await uploadImage({ storyId: story.id, file: avatar })
                .unwrap()
                .catch(e => console.error(e));
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu bajki.' }));
            }
          }
          await refetchTemplate()
            .unwrap()
            .catch(e => console.error(e));
          await refetchStory()
            .unwrap()
            .catch(e => console.error(e));
          dispatch(addAlert({ color: 'success', text: 'Edytowano bajkę', clearable: false }));
          router.navigate(paths.admin.story.list);
        })
        .catch(e => console.error(e));
    } else {
      createTemplate({
        templateDTO: {
          templateParams,
          variants: data.variants
        },
        storyDTO: {
          title: data.title,
          content: data.original,
          description: data.description,
          ownerId: 'system-user',
          creator: Creator.SYSTEM,
          readAccessLevel: SubscriptionType.FREE,
          customizeAccessLevel: SubscriptionType.BASIC,
          hidden: data.hidden,
          pegi: data.pegi,
          activitiesAndGames: data.activitiesAndGames?.activitiesAndGames,
          activitiesAndGamesLexical: data.activitiesAndGames?.activitiesAndGamesLexical,
          talkSubjects: data.talkSubjects?.talkSubjects,
          talkSubjectsLexical: data.talkSubjects?.talkSubjectsLexical,
          tags: selectedTags.map(st => ({ predefined: true, value: st.value }))
        }
      })
        .unwrap()
        .then(async response => {
          if (avatar) {
            try {
              await uploadImage({ storyId: response.storyDTO.id, file: avatar })
                .unwrap()
                .catch(e => console.error(e));
            } catch (error) {
              dispatch(addAlert({ color: 'danger', text: 'Nie udało się zapisać obrazu bajki.' }));
            }
          }

          dispatch(addAlert({ color: 'success', text: 'Utworzono bajkę', clearable: false }));
          router.navigate(paths.admin.story.list);
        })
        .catch(e => console.error(e));
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    reset: resetForm,
    watch,
    setValue
  } = useForm<StoryEditorFormValues>({
    mode: 'onBlur'
  });

  useEffect(() => {
    if (story && template) {
      resetForm({
        title: story.title,
        original: story.content,
        description: story.description,
        charactersNumber: template.templateParams.length.toString(),
        hidden: story.hidden,
        pegi: story.pegi,
        variants: template.variants,
        activitiesAndGames: { activitiesAndGames: story.activitiesAndGames, activitiesAndGamesLexical: story.activitiesAndGamesLexical },
        talkSubjects: { talkSubjects: story.talkSubjects, talkSubjectsLexical: story.talkSubjectsLexical }
      });

      setSelectedTags(story.tags);
    }
  }, [story, template, resetForm]);

  const charactersNumber = watch('charactersNumber');
  useEffect(() => {
    switch (+charactersNumber) {
      case 1:
        if (template?.templateParams.length === +charactersNumber) {
          setValue(
            'variants',
            template?.variants.map(v => ({ template: v.template, templateParams: v.templateParams }))
          );
        } else {
          setValue(
            'variants',
            _.range(2).map(() => ({ template: '', templateParams: [{ key: 'MAIN_HERO1', gender: 'm1' }] }))
          );
        }
        break;
      case 2:
        if (template?.templateParams.length === +charactersNumber) {
          setValue(
            'variants',
            template?.variants.map(v => ({ template: v.template, templateParams: v.templateParams }))
          );
        } else {
          setValue(
            'variants',
            _.range(4).map(() => ({
              template: '',
              templateParams: [
                { key: 'MAIN_HERO1', gender: 'm1' },
                { key: 'SIDE_HERO1', gender: 'm1' }
              ]
            }))
          );
        }

        break;
      case 3:
        if (template?.templateParams.length === +charactersNumber) {
          setValue(
            'variants',
            template?.variants.map(v => ({ template: v.template, templateParams: v.templateParams }))
          );
        } else {
          setValue(
            'variants',
            _.range(9).map(() => ({
              template: '',
              templateParams: [
                { key: 'MAIN_HERO1', gender: 'm1' },
                { key: 'SIDE_HERO1', gender: 'm1' },
                { key: 'SIDE_HERO2', gender: 'm1' }
              ]
            }))
          );
        }
        break;
      default:
        setValue('variants', []);
    }
  }, [template, charactersNumber, setValue]);

  const prevUseGenerating = usePrevious(isGenerating);
  useEffect(() => {
    if (prevUseGenerating && !isGenerating) {
      refetchStory();
    }
  }, [prevUseGenerating, isGenerating, refetchStory]);

  useInterval(
    () => {
      refetchIsGenerating();
    },
    isGenerating ? 10000 : null
  );

  const variants = watch('variants');

  const activitiesAndGamesInitialConfig: InitialConfigType = {
    namespace: 'activitiesAndGames',
    onError: e => console.warn(e),
    nodes: [HeadingNode, ListNode, ListItemNode],
    editorState: story?.activitiesAndGamesLexical
  };

  const talkSubjectsInitialConfig: InitialConfigType = {
    namespace: 'talkSubjects',
    onError: e => console.warn(e),
    nodes: [HeadingNode, ListNode, ListItemNode],
    editorState: story?.talkSubjectsLexical
  };

  const onActivitiesAndGamesChange = (
    editorState: EditorState,
    editor: LexicalEditor,
    field: ControllerRenderProps<StoryEditorFormValues, 'activitiesAndGames'>
  ) => {
    const activitiesAndGames = editor.read<string>(() => {
      return $generateHtmlFromNodes(editor, null);
    });
    const activitiesAndGamesLexical = JSON.stringify(editorState);
    field.onChange({ activitiesAndGames, activitiesAndGamesLexical });
  };

  const onTalkSubjectsChange = (
    editorState: EditorState,
    editor: LexicalEditor,
    field: ControllerRenderProps<StoryEditorFormValues, 'talkSubjects'>
  ) => {
    const talkSubjects = editor.read<string>(() => {
      return $generateHtmlFromNodes(editor, null);
    });

    const talkSubjectsLexical = JSON.stringify(editorState);

    field.onChange({ talkSubjects, talkSubjectsLexical });
  };

  const onTagClick = (tag: PredefinedTag) => {
    setSelectedTags(prev => {
      if (prev.find(pt => pt.value === tag.value)) {
        return prev.filter(pt => pt.value !== tag.value);
      } else {
        return [...prev, { predefined: true, value: tag.value }];
      }
    });
  };

  if (storyId && !story) {
    return null;
  }

  if (templateId && !template) {
    return null;
  }

  return (
    <RouteLayout
      hideBottomNavigation
      backRoute={paths.admin.story.base}
      bottomElement={
        audioBlob ? (
          <div className="pt-3">
            <AudioPlayer
              trackSrc={audioBlob}
              duration={story?.audio?.lengthInSeconds ?? 0}
              startingTime={0}
              onTimeChangedCallback={_.noop}
            />
          </div>
        ) : isGenerating ? (
          <Typography variant="h4" classNames="d-flex justify-content-center w-100 py-5">
            Audio w trakcie generowania...
          </Typography>
        ) : undefined
      }
    >
      <div className="mb-5">
        <Typography variant="h1" classNames="mb-5">
          Obraz
        </Typography>

        <ImageEdit onFileChangeCallback={setAvatar} file={imageBlob} />

        <Divider spacing="my-5" />

        <div>
          {story ? (
            <>
              <AdminNarratorPicker setSelectedNarratorCallback={setSelectedNarrator} selectedNarrator={selectedNarrator} />
              <Button
                className={'long'}
                variant="outline-primary mt-5"
                disabled={isGenerating}
                onClick={() => {
                  if (selectedNarrator?.id && story?.id) {
                    generateAudio({ narratorId: selectedNarrator?.id, storyId: story?.id }).then(() => {
                      refetchIsGenerating();
                      refetchStory();
                    });
                  }
                }}
              >
                Zmień audio bajki.
              </Button>
              <Typography variant="h1" classNames="mt-4">
                Uwaga! Obecne audio będzie nadpisane!
              </Typography>
            </>
          ) : (
            <Typography variant="h1" classNames="mb-5">
              Uwaga! Opcja generowania audio do bajki będzie dostępna po stworzeniu bajki. Należy wejść w edycję bajki po jej utworzeniu.
            </Typography>
          )}
        </div>

        <Divider spacing="my-5" />

        <Form.Group className="mb-3 d-flex align-items-center gap-2" controlId="storyEditorForm.hidden">
          <Controller
            name="hidden"
            control={control}
            render={({ field }) => (
              <Form.Check
                {...field}
                value={''}
                checked={field.value}
                onChange={e => field.onChange(e.target.checked)}
                type="checkbox"
                inline
                label="Bajka ukryta (nie będzie widoczna dla żadnego użytkownika oprócz adminów)"
              />
            )}
          />
        </Form.Group>

        <Divider spacing="my-5" />

        <Form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Form.Group className="mb-3" controlId="storyEditorForm.title">
            <Form.Label>Tytuł</Form.Label>
            <Controller
              name="title"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Control {...field} placeholder="Tytuł" isInvalid={fieldState.invalid} />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{
                required: validationMessage.required
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="storyEditorForm.original">
            <Form.Label>Treść bajki</Form.Label>
            <Controller
              name="original"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Control {...field} as="textarea" placeholder="Treść bajki" isInvalid={fieldState.invalid} />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{
                required: validationMessage.required
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="storyEditorForm.description">
            <Form.Label>Opis bajki</Form.Label>
            <Controller
              name="description"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Control {...field} as="textarea" placeholder="Opis bajki" isInvalid={fieldState.invalid} />
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{
                required: validationMessage.required
              }}
            />
          </Form.Group>

          <Divider spacing={'my-5'} />

          <Typography variant="h1" classNames="mb-5">
            Tagi - czyli kategorie bajki
          </Typography>
          <JustifyCenter classNames="gap-2 flex-wrap pt-4">
            {predefinedTags.map(t => {
              const isActive = selectedTags.find(st => t.value === st.value) !== undefined;
              return <TagBadge key={t.id} value={t.value} isActive={isActive} onClickCallback={() => onTagClick(t)} />;
            })}
          </JustifyCenter>

          <Divider spacing={'my-5'} />

          <Form.Group className="mb-3" controlId="storyEditorForm.pegi">
            <Form.Label>Pegi</Form.Label>
            <Controller
              name="pegi"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Select {...field} isInvalid={fieldState.invalid} defaultValue={'-1'}>
                    <option disabled hidden value={'-1'}>
                      Wybierz pegi
                    </option>
                    <option value={1}>3</option>
                    <option value={2}>5</option>
                    <option value={3}>7</option>
                  </Form.Select>
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{ required: validationMessage.required }}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Aktywności i zabawy</Form.Label>

            <Controller
              name="activitiesAndGames"
              control={control}
              render={({ field }) => (
                <LexicalComposer initialConfig={activitiesAndGamesInitialConfig}>
                  <div className="editor-shell">
                    <ToolbarPlugin setIsLinkEditMode={_.noop} />
                    <div className={`editor-container`}>
                      <RichTextPlugin
                        contentEditable={
                          <div className="editor-scroller">
                            <div className="editor">
                              <ContentEditable placeholder="Aktywności i zabawy" />
                            </div>
                          </div>
                        }
                        ErrorBoundary={LexicalErrorBoundary}
                      />
                      <HistoryPlugin />
                      <CheckListPlugin />
                      <ListPlugin />
                      <OnChangePlugin
                        onChange={(editorState: EditorState, editor: LexicalEditor) =>
                          onActivitiesAndGamesChange(editorState, editor, field)
                        }
                      />
                    </div>
                  </div>
                </LexicalComposer>
              )}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Tematy do rozmów</Form.Label>

            <Controller
              name="talkSubjects"
              control={control}
              render={({ field }) => (
                <LexicalComposer initialConfig={talkSubjectsInitialConfig}>
                  <div className="editor-shell">
                    <ToolbarPlugin setIsLinkEditMode={_.noop} />
                    <div className={`editor-container`}>
                      <RichTextPlugin
                        contentEditable={
                          <div className="editor-scroller">
                            <div className="editor">
                              <ContentEditable placeholder="Tematy do rozmów" />
                            </div>
                          </div>
                        }
                        ErrorBoundary={LexicalErrorBoundary}
                      />
                      <HistoryPlugin />
                      <CheckListPlugin />
                      <ListPlugin />
                      <OnChangePlugin
                        onChange={(editorState: EditorState, editor: LexicalEditor) => onTalkSubjectsChange(editorState, editor, field)}
                      />
                    </div>
                  </div>
                </LexicalComposer>
              )}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="storyEditorForm.charactersNumber">
            <Form.Label>Liczba bohaterów</Form.Label>
            <Controller
              name="charactersNumber"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <Form.Select {...field} isInvalid={fieldState.invalid} defaultValue={'-1'}>
                    <option disabled hidden value={'-1'}>
                      Wybierz liczbę bohaterów
                    </option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                  </Form.Select>
                  {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                </>
              )}
              rules={{ required: validationMessage.required }}
            />
          </Form.Group>

          <Divider spacing={'my-5'} />

          <Typography variant="h1" classNames="mb-5">
            Warianty
          </Typography>

          {variants?.map((variant, vIdx) => {
            return (
              <>
                <div className="d-flex gap-5 align-items-center">
                  {variant.templateParams?.map((tp, tpIdx) => {
                    return (
                      <>
                        <Form.Group className="mb-3" controlId={`storyEditorForm.gender-${vIdx}-${tpIdx}`}>
                          <Form.Label>Płeć {tp.key}</Form.Label>
                          <Controller
                            name={`variants.${vIdx}.templateParams.${tpIdx}.gender`}
                            control={control}
                            render={({ field, fieldState }) => (
                              <>
                                <Form.Select {...field} isInvalid={fieldState.invalid} defaultValue={tp.gender}>
                                  <option disabled hidden value={'-1'}>
                                    Wybierz płeć
                                  </option>
                                  <option value={'m1'}>Męska</option>
                                  <option value={'f'}>Żeńska</option>
                                </Form.Select>
                                {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                              </>
                            )}
                            rules={{ required: validationMessage.required }}
                          />
                        </Form.Group>
                      </>
                    );
                  })}
                </div>

                <Form.Group className="mb-3" controlId={`storyEditorForm.variant-${vIdx}`}>
                  <Form.Label>Wariant {vIdx + 1}</Form.Label>
                  <Controller
                    name={`variants.${vIdx}.template`}
                    control={control}
                    render={({ field, fieldState }) => (
                      <>
                        <Form.Control {...field} as="textarea" placeholder="Wariant bajki" isInvalid={fieldState.invalid} />
                        {fieldState.invalid ? <FormError error={fieldState.error?.message} /> : ''}
                      </>
                    )}
                    rules={{
                      required: validationMessage.required
                    }}
                  />
                </Form.Group>
                <Divider spacing={'my-5'} />
              </>
            );
          })}
          <JustifyCenter classNames="pt-5 pb-2">
            <Button type="submit" disabled={!isValid}>
              Zapisz
            </Button>
          </JustifyCenter>
        </Form>
      </div>
    </RouteLayout>
  );
};
