import { Typography } from 'features/ui/typography/typography';
import { Badge, Card } from 'react-bootstrap';
import { ArrowRightIcon } from 'shared/icons/arrowRightIcon';
import { Product, TokenPackageProperties } from 'shared/types/product';
import { cGrey200, cPrimary, cSecondary, cWhite } from 'shared/utils/styleCommon';
import { useInitTransactionMutation } from 'store/api/endpoints/paymentEndpoints';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addAlert } from 'store/slices/alertsSlice';
import tokenIcon from '../../../shared/icons/tokenIcon.svg';

type Props = {
  product: Product & { properties: TokenPackageProperties };
  onRedirectWait: () => void;
};

export const TokenPackageItem = (props: Props) => {
  // redux
  const loggedUser = useAppSelector(state => state.authSlice.loggedUser);
  const dispatch = useAppDispatch();
  // rtk
  const [initTransaction] = useInitTransactionMutation();

  const getTokenPackageHeader = (backgroundColor: string, leftSideText: string) => {
    return (
      <div
        className="d-flex justify-content-between w-100"
        style={{ backgroundColor: backgroundColor, paddingLeft: '12px', padding: '4px 12px' }}
      >
        <Typography variant="description-xxs" styles={{ color: cWhite }}>
          {leftSideText}
        </Typography>

        <Typography variant="description-xxs" styles={{ color: cWhite }}>
          {props.product.description}
        </Typography>
      </div>
    );
  };
  const getPackageDisplayProps = () => {
    if (props.product.properties.popularChoice) {
      return {
        header: getTokenPackageHeader(cPrimary, 'POPULARNY WYBÓR'),
        border: `2px solid ${cPrimary}`,
        background: cWhite,
        textColor: cSecondary,
        badgeVariant: 'token-amount-secondary'
      };
    } else if (props.product.properties.bestValue) {
      return {
        header: getTokenPackageHeader(cSecondary, 'NAJLEPSZA WARTOŚĆ'),
        border: 'none',
        background: cSecondary,
        textColor: cWhite,
        badgeVariant: 'token-amount-grey'
      };
    } else {
      return {
        header: null,
        border: `2px solid ${cGrey200}`,
        background: cWhite,
        textColor: cSecondary,
        badgeVariant: 'token-amount-secondary'
      };
    }
  };

  const showErrorAlert = () => {
    dispatch(
      addAlert({
        color: 'danger',
        text: 'Nie udało się przejść do strony pośrednika płatności. Proszę spróbować ponownie lub skontaktować się z obsługą klienta, jeśli problem będzie się powtarzał.'
      })
    );
  };

  const displayProps = getPackageDisplayProps();

  return (
    <Card
      className="mb-3"
      style={{
        boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.08)',
        borderRadius: '5px',
        border: displayProps.border,
        overflow: 'hidden',
        backgroundColor: displayProps.background
      }}
      onClick={async () => {
        if (loggedUser?.email) {
          props.onRedirectWait();
          try {
            const transactionResponse = await initTransaction({ productId: props.product.id }).unwrap();
            if (transactionResponse.data?.confirmationUrl) {
              const hackElement = document.createElement('a'); // Safari hack
              hackElement.href = transactionResponse.data?.confirmationUrl;
              hackElement.target = '_self';
              document.body.append(hackElement);
              hackElement.click();
              hackElement.remove();
            }
          } catch (e) {
            showErrorAlert();
          }
        }
      }}
      role="button"
    >
      {displayProps.header}

      <div className="d-flex flex-row justify-content-between py-4 px-3">
        <div className="d-flex align-items-start justify-content-start gap-3">
          <div style={{ position: 'relative' }} className="d-flex justify-content-center align-items-center px-2">
            <img src={tokenIcon} alt="" width="54px" />
            <Badge bg={displayProps.badgeVariant} style={{ position: 'absolute', left: 0, bottom: 0 }}>
              x{props.product.properties.tokens}
            </Badge>
          </div>

          <div className="d-flex flex-column justify-content-start gap-1">
            <Typography variant="h4" styles={{ color: displayProps.textColor }}>
              {props.product.name}
            </Typography>
            <Typography variant="description" styles={{ color: displayProps.textColor }}>
              {props.product.price} PLN
            </Typography>
          </div>
        </div>
        <ArrowRightIcon />
      </div>
    </Card>
  );
};
