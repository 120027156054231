import { EpochParam, HeroParam, MoralParam, TagParam, WorldParam, WritingStyleParam } from 'store/slices/storyCreatorSlice';
import { Audio } from './audio';
import { PredefinedNarrator } from './narrator';
import { Tag } from './tag';
import { TemplateParam } from './template';
import { SubscriptionType } from './users';

export interface StoryPreview {
  storyId: string;
  title: string;
  limitedContent: string;
  description?: string;
  creator: Creator;
  ownerId: string;
  image?: string;
  readAccessLevel?: SubscriptionType;
  customizeAccessLevel?: SubscriptionType;
  audioLength?: number;
  templateId?: string;
}

export interface Story {
  id: string;
  title: string;
  content: string;
  description?: string;
  image: string;
  audio?: Audio;
  tags: Tag[];
  world?: string;
  moral?: string;
  recipient?: Recipient;
  ownerId: string;
  creator: Creator;
  readAccessLevel?: SubscriptionType;
  customizeAccessLevel?: SubscriptionType;
  templateId?: string;
  templateParams?: TemplateParam[];
  hidden: boolean;
  activitiesAndGames?: string;
  activitiesAndGamesLexical?: string;
  talkSubjects?: string;
  talkSubjectsLexical?: string;
  pegi: number;
}

export interface Recipient {
  name?: string;
  age?: number;
  gender?: string;
  interests: string[];
}

export enum StoryLength {
  SHORT = 'SHORT',
  // MEDIUM = 'MEDIUM', // TODO when generator api will be able to handle these values
  LONG = 'LONG'
  // VERY_LONG = 'VERY_LONG'
}

export const convertLengthToString = (storyLength: StoryLength) => {
  switch (storyLength) {
    case StoryLength.SHORT:
      return 'Bajka średniej długości';
    // case StoryLength.MEDIUM: // TODO add more lengths when generator is ready for it
    //   return 'Średnia';
    // case StoryLength.LONG:
    //   return 'Długa';
    // case StoryLength.VERY_LONG:
    //   return 'Epicka saga';
    default:
      return 'Nieznana długość';
  }
};

export enum Creator {
  SYSTEM = 'SYSTEM',
  USER = 'USER'
}

export interface CustomStoryParams {
  narrator: PredefinedNarrator;
  recipient?: string;
  mainHero?: HeroParam;
  sideHero?: HeroParam;
  world?: WorldParam;
  moral?: MoralParam;
  epoch?: EpochParam;
  tags?: TagParam;
  writingStyle?: WritingStyleParam;
  storyLength?: StoryLength;
}

export interface TemplateStoryParams {
  narrator: PredefinedNarrator;
  templateParams: Map<string, string>;
}
